import * as _video2 from "video.js";

var _video = "default" in _video2 ? _video2.default : _video2;

import * as _window2 from "global/window";

var _window = "default" in _window2 ? _window2.default : _window2;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

/*! @name videojs-landscape-fullscreen @version 11.1111.0 @license ISC */
!function (e, n) {
  exports = n(_video, _window);
}(exports, function (e, n) {
  "use strict";

  function t(e) {
    return e && "object" == typeof e && "default" in e ? e : {
      default: e
    };
  }

  var o = t(e),
      i = t(n),
      r = {
    fullscreen: {
      enterOnRotate: !0,
      exitOnRotate: !0,
      alwaysInLandscapeMode: !0,
      iOS: !0
    }
  },
      l = i.default.screen;

  l.lockOrientationUniversal = function (e) {
    return l.orientation && l.orientation.lock(e).then(function () {}, function (e) {
      return console.log(e);
    }) || l.mozLockOrientation && l.mozLockOrientation(e) || l.msLockOrientation && l.msLockOrientation(e);
  };

  var a = function () {
    return "number" == typeof i.default.orientation || l && l.orientation && l.orientation.angle ? i.default.orientation : (o.default.log("angle unknown"), 0);
  },
      u = o.default.registerPlugin || o.default.plugin,
      s = function (e) {
    var n = this || _global;
    (o.default.browser.IS_ANDROID || o.default.browser.IS_IOS) && this.ready(function () {
      !function (e, n) {
        e.addClass("vjs-landscape-fullscreen"), n.fullscreen.iOS && o.default.browser.IS_IOS && o.default.browser.IOS_VERSION > 9 && !e.el_.ownerDocument.querySelector(".bc-iframe") && (e.tech_.el_.setAttribute("playsinline", "playsinline"), e.tech_.supportsFullScreen = function () {
          return !1;
        });

        var t = function () {
          var t = a();
          90 !== t && 270 !== t && -90 !== t || n.fullscreen.enterOnRotate && !1 === e.paused() && (e.requestFullscreen(), l.lockOrientationUniversal("landscape")), 0 !== t && 180 !== t || n.fullscreen.exitOnRotate && e.isFullscreen() && e.exitFullscreen();
        };

        o.default.browser.IS_IOS ? i.default.addEventListener("orientationchange", t) : l && l.orientation && (l.orientation.onchange = t), e.on("fullscreenchange", function (t) {
          (o.default.browser.IS_ANDROID || o.default.browser.IS_IOS) && !a() && e.isFullscreen() && n.fullscreen.alwaysInLandscapeMode && l.lockOrientationUniversal("landscape");
        }), e.on("dispose", function () {
          i.default.removeEventListener("orientationchange", t);
        });
      }(n, o.default.mergeOptions(r, e));
    });
  };

  return u("landscapeFullscreen", s), s.VERSION = "11.1111.0", s;
});
export default exports;